import { Breadcrumb, Button, Dropdown, Empty, Input, Layout, notification, Select, Space, Table, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { DownOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import { Helmet } from "react-helmet";
import { apiListEventsPay, apiPaymentDownload } from "../../../api/events";
import './EventPay.scss';

const EventPay = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { id } = useParams();
  const { name } = useParams();
  const [loading, setLoading] = useState(true);
  // PAYMENT LIST
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [payments, setPayments] = useState([]);

  // Paginação
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  // Função de busca
  const handleSearch = async (value) => {
    setSearchText(value);
    setLoading(true)
    try {
      if (value === '') {
        await fetchPayments(pagination.current);
      } else {
        const json = await apiListEventsPay(id, {
          search: value,
          status: statusFilter,
          page: 1,
          page_size: pagination.pageSize,
        });
        setPayments(json.results || []);
        setPagination({
          ...pagination,
          total: json.count || 0,
          current: 1,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false)
    }
  };

  // Função de busca com paginação
  const fetchPayments = async (page) => {
    setLoading(true)
    try {
      const json = await apiListEventsPay(id, {
        search: searchText,
        status: statusFilter,
        page: page,
        page_size: pagination.pageSize,
      });
      setPayments(json.results || []);
      setPagination({
        ...pagination,
        total: json.count || 0,
        current: page,
      });
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: 'Ocorreu um erro ao buscar os dados.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (id) {
      fetchPayments(pagination.current);
    }
  }, [searchText, statusFilter, pagination.pageSize]);

  const handleTableChange = (pagination) => {
    fetchPayments(pagination.current);
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value === undefined ? '' : value);
    setPagination(prev => ({
      ...prev,
      current: 1,
    }));
  };

  const statusTranslations = {
    FREE: "Gratuito",
    EXPIRED: "Expirado",
    PENDING: "Pendente",
    PAID: "Pago",
    CANCELED: "Cancelado"
  };

  const columnsPayment = [
    {
      title: 'Nome',
      dataIndex: ['customer', 'name'],
      key: 'name',
      render: (name, { status }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{name}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status-color ${statusTranslations[status]}`}>{statusTranslations[status]}</span>
      )
    },
    {
      title: 'Email',
      dataIndex: ['customer', 'email'],
      key: 'email',
      responsive: ["lg"],
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      key: 'total',
      render: total => `R$ ${(total / 100).toFixed(2)}`,
      responsive: ["lg"],
    },
    {
      title: 'Data da compra',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => new Date(created_at).toLocaleString(),
      responsive: ["xl"],
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`/evento/compras/detalhe/${id}/${record.id}`)} >
          Visualizar
        </Button>
      ),
    },
  ];

  const items = [
    {
      label: 'CSV',
      key: 'csv',
      icon: <FileDoneOutlined />,
    },
    {
      label: 'EXCEL',
      key: 'excel',
      icon: <FileDoneOutlined />,
    },
  ];

  const handleDownload = async (format) => {
    try {
      const blob = await apiPaymentDownload(id, format);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `detalhes-pagamento-${name}.${format === 'csv' ? 'csv' : 'xlsx'}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const handleMenuClick = (e) => {
    handleDownload(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  return (
    <div className='EventPay' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Compras Eventos - {name}</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          style={{
            padding: '23px 32px 100px 32px',
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Compras Evento - {name}</Breadcrumb.Item>
          </Breadcrumb>

          <div className="container-table">
            <Space style={{ marginBottom: 16 }} className="box-filters">
              <Input
                style={{ height: 32 }}
                placeholder="Pesquisar"
                onChange={e => handleSearch(e.target.value)}
              />
              <Select
                placeholder="Filtrar por status"
                onChange={handleStatusChange}
                allowClear
                value={statusFilter}
                style={{ width: 200 }}
              >
                <Option value="FREE">Gratuito</Option>
                <Option value="EXPIRED">Expirado</Option>
                <Option value="PENDING">Pendente</Option>
                <Option value="PAID">Pago</Option>
                <Option value="CANCELED">Cancelado</Option>
                <Option value="">Todos</Option>
              </Select>
            </Space>

            <Table
              columns={columnsPayment}
              dataSource={payments}
              loading={loading}
              rowKey="id"
              locale={{ emptyText: <Empty description="Sem dados" /> }}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: false,
                onChange: (page, pageSize) => {
                  setPagination(prev => ({
                    ...prev,
                    current: page,
                    pageSize: pageSize,
                  }));
                  fetchPayments(page);
                },
              }}
              onChange={handleTableChange}
            />
          </div>
          <div className="download-section">
            <Dropdown menu={menuProps} >
              <Button>
                <Space>
                  Baixar Relatório
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </Content >
      </Layout >
    </div >
  )
}

export default EventPay;
