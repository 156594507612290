import { Button, Divider } from 'antd';
import React from 'react';
import './TypeSelection.scss';

const TypeSelection = ({ onNext }) => {
  const handleTypeSelection = (type) => {
    onNext(type);
  };
  return (
    <div className="TypeSelection">
      <h2>Selecione o tipo de conta</h2>
      <Divider />
      <div className="container-buttons-type">
        <Button
          type="primary"
          size="large"
          className="button-form"
          onClick={() => handleTypeSelection("SUB_SELLER_PF")}
        >
          PESSOA FÍSICA
        </Button>
        <Button
          type="primary"
          size="large"
          style={{ marginLeft: 16 }}
          className="button-form"
          onClick={() => handleTypeSelection("SUB_SELLER_PJ")}
        >
          PESSOA JURÍDICA
        </Button>
      </div>
    </div>
  )
}

export default TypeSelection
