import { BarsOutlined, DeleteFilled, EditOutlined, LinkOutlined, SmileOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Layout, Modal, Skeleton, message, notification, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { apiDeleteEvent, apiListEvents } from "../../../api/events";
import { ReactComponent as Computer } from "../../../assets/images/events/computer.svg";
import { ReactComponent as EmptyEvents } from "../../../assets/images/events/logo-empty.svg";
import { ReactComponent as TwoPersons } from "../../../assets/images/events/two-person.svg";

import { Helmet } from "react-helmet";
import './Events.scss';

const Events = () => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const handleSimulate = async (page) => {
    setLoading(true);
    try {
      const json = await apiListEvents({ page });
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: page,
      }));
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error?.status === 400) {
        notification.error({
          message: "Erro",
          description: "Ocorreu um erro ao listar os planos de pagamento.",
          placement: "topRight",
        });
        setErrorStatus("404");
      } else if (error?.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao listar os planos de pagamento. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
        notification.error("Erro ao buscar os planos de pagamento.");
      }
    } finally {
      setLoading(false);
    }
  };

  const onChange = (pagination) => {
    handleSimulate(pagination.current);
  };

  useEffect(() => {
    handleSimulate(pagination.current);
  }, [pagination.current]);


  const handleDelete = async (eventID) => {
    try {
      const eventResponse = await apiDeleteEvent(eventID);
      if (eventResponse.success) {
        message.success("Evento deletado com sucesso!");
        handleSimulate(pagination.current);
      } else {
        message.error("Falha ao deletar o evento.");
      }

    } catch (error) {
      message.error("Erro ao deletar o evento. Verifique os detalhes e tente novamente.");
    }
  };

  const confirmDelete = (eventID) => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar?',
      content: 'Essa ação não pode ser desfeita.',
      okText: 'Deletar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => handleDelete(eventID),
    });
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success("Link copiado com sucesso!");
    });
  };
  return (
    <div className='Events' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Eventos</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          style={{
            padding: '23px 32px 100px 32px',
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Meus eventos</Breadcrumb.Item>
          </Breadcrumb>

          {loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            filteredData.length < 1 && !loading && <div className="container-empty-events">
              <EmptyEvents />
              <h2>Crie seu primeiro evento</h2>
              <p className="description-empty">
                Você tem total liberdade para cadastrar, gerenciar e monitorar todas as informações do seu evento
              </p>
              <div className="container-empty-buttons">
                <span>Selecione o tipo de evento</span>
                <div className="empty-buttons">
                  <button onClick={() => navigate("/eventos/criar", { state: { type: "Presencial" } })}><TwoPersons /> Presencial</button>
                  <button onClick={() => navigate("/eventos/criar", { state: { type: "Online" } })}><Computer /> Online</button>
                </div>
              </div>
            </div>
          )}
          {!loading && filteredData.length >= 1 && <div className="top-container-events">
            <div className="close-alert">
              <Alert
                message="Você tem total liberdade para cadastrar, gerenciar e monitorar todas as informações do seu evento"
                type="success"
                className="alert-success"
                showIcon
                icon={<SmileOutlined />}
                closable
              />
            </div>
            <div className="buttons-create-event">
              <p>Criar novo evento</p>
              <div className="buttons">
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Presencial" } })}><TwoPersons /> Presencial</button>
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Online" } })}><Computer /> Online</button>
              </div>
            </div>
          </div>}

          <div className="container-events-show">
            {loading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <div className="container-event" key={index}>
                  <Skeleton active paragraph={{ rows: 0 }} />
                  <div className="container-event-image">
                    <Skeleton.Node active style={{ width: 279, height: 150 }} />
                  </div>
                  <div className="container-event-info">
                    <Skeleton active paragraph={{ rows: 0 }} />
                    <div className="container-event-buttons">
                      <button className="button-event-edit">
                        <Skeleton.Button active type="primary" />
                      </button>
                      <button className="button-event-show">
                        <Skeleton.Button active type="primary" />
                      </button>
                      <button className="button-event-delete">
                        <Skeleton.Button active type="primary" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              filteredData.map((event, index) => (
                <div className="container-event" key={index}>
                  <p className="event-title">{event.name}</p>
                  <div className="container-event-image">
                    <img src={event.image_url} alt="Imagem de fundo" onClick={() => handleCopy(event.link)} />
                    <div className="button-container">
                      <button className="button-event-delete" onClick={() => handleCopy(event.link)}>
                        <LinkOutlined />
                      </button>
                    </div>
                  </div>
                  <div className="container-event-info">
                    <p className="event-description">{event.name}</p>
                    <div className="container-event-buttons">
                      <button className="button-event-edit" onClick={() => navigate(`/eventos/${event.id}`)}>
                        <EditOutlined />
                      </button>
                      <button className="button-event-show" onClick={() => confirmDelete(event.id)}>
                        <DeleteFilled />
                      </button>
                      <button className="button-event-delete" onClick={() => navigate(`/evento/compras/${event.name}/${event.id}`)}>
                        <BarsOutlined />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

        </Content>
      </Layout>
    </div>
  )
}

export default Events;
