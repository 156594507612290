import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiCreateEventLogo = async (file) => {
  if (!file) {
    throw new Error("File is required");
  }

  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/upload_logo/`, {
      method: "POST",
      headers: {},
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiCreateEvent = async (payload) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiListEvents = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetEvent = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiEditEvent = async (id, payload) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/`, {
      method: "PUT",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteEvent = async (id) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    if (response.status === 204) {
      return { success: true };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || "Erro ao deletar o evento");
    }
  } catch (error) {
    throw error;
  }
};

export const apiCreateEventTicket = async (id, payload) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/tickets/`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetEventTickets = async (id, ticketID) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/tickets/`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetEventTicketDetail = async (id, ticketID) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/tickets/${ticketID}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiEditEventTicket = async (id, ticketID, payload) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/tickets/${ticketID}/`, {
      method: "PUT",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiDeleteEventTicket = async (id, ticketID) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/tickets/${ticketID}/`, {
      method: "DELETE",
      headers: buildHeaders(),
    });
    if (response.status === 204) {
      return { success: true };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || "Erro ao deletar o ticket");
    }
  } catch (error) {
    throw error;
  }
};

export const apiGetEventNoAuth = async (id) => {
  try {
    const response = await fetch(`${baseSiteEndPoint}/event/event/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Erro na resposta: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiListEventsPay = async (eventID, filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${eventID}/checkouts`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetEventCheckoutDetails = async (eventID, checkoutID) => {
  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${eventID}/checkouts/${checkoutID}/`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPaymentDownload = async (id, format) => {
  try {
    const acceptHeader =
      format === 'csv'
        ? 'text/csv'
        : 'application/vnd.ms-excel';

    const response = await authorizedFetch(
      `${baseSiteEndPoint}/event/seller/${getSellerID()}/events/${id}/checkouts/report/`,
      {
        method: 'GET',
        headers: {
          ...buildHeaders(),
          Accept: acceptHeader,
        },
      }
    );

    return response.blob();
  } catch (error) {
    throw error;
  }
};