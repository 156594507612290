import { Button, Divider, Form, Input } from 'antd';
import { motion } from "motion/react";
import React from 'react';
import './RegisterPerson.scss';

const RegisterPerson = ({ onSubmitRegister, loginError, loading }) => {
  return (
    <motion.div
      className="RegisterPerson"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
    >
      <h2>Criação de Login</h2>
      <Divider />
      <Form
        name="register_form"
        layout="vertical"
        className="register-form"
        initialValues={{ remember: true }}
        onFinish={onSubmitRegister}
      >
        <div className='container-inputs'>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Por favor, insira seu e-mail!' },
              { type: 'email', message: 'O e-mail inserido não é válido!' },
            ]}
          >
            <Input placeholder="Email" size="small" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Senha"
            rules={[
              { required: true, message: 'Por favor, insira sua senha!' },
              {
                min: 8,
                message: "A senha deve ter pelo menos 8 caracteres",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
                message:
                  "A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial",
              }
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Senha"
              size="small"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirmar Senha"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Por favor, confirme sua senha!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas não coincidem!'));
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Confirmar Senha"
              size="small"
            />
          </Form.Item>
        </div>

        <Form.Item>
          <div className="container-buttons">
            <Button
              type="primary"
              htmlType="submit"
              className="button-form"
              loading={loading}
              size="large"
            >
              Continuar
            </Button>
          </div>
        </Form.Item>
      </Form>

    </motion.div>
  );
};

export default RegisterPerson;
