import { Breadcrumb, DatePicker, Layout, Skeleton, Space, Spin, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { apiListEvents } from "../../../api/events";
import { apiEventSalesByDate, apiTicketSalesByDate, apiTicketSalesByDateAndEvent, apiTicketSalesByHour } from "../../../api/eventSales";
import { ReactComponent as Clock } from "../../../assets/images/dashboard/clock.svg";
import { ReactComponent as EmptyEvents } from "../../../assets/images/dashboard/logo-empty.svg";
import { ReactComponent as Money } from "../../../assets/images/dashboard/money.svg";
import { ReactComponent as Ticket } from "../../../assets/images/dashboard/ticket.svg";
import { ReactComponent as Computer } from "../../../assets/images/events/computer.svg";
import { ReactComponent as TwoPersons } from "../../../assets/images/events/two-person.svg";
import { formatPriceInCents } from "../../../utils/format";
import './Home.scss';

const Home = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [dataFetched, setDataFetched] = useState({
    totalRevenue: null,
    ticketsSales: null,
    ticketsSalesByDateAndEvent: null,
  });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // DateFilter
  dayjs.locale('pt-br');
  const getDateRange = () => {
    const today = dayjs();
    const dateAfter = today.subtract(30, "day");
    const dateBefore = today.add(1, "day");

    return {
      date_after: dateAfter.format("YYYY-MM-DD"),
      date_before: dateBefore.format("YYYY-MM-DD"),
    };
  };
  const { date_after, date_before } = getDateRange();
  const [dateRange, setDateRange] = useState([dayjs(date_after), dayjs(date_before)]);

  const handleChange = (dates) => {
    setDateRange(dates);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const json = await apiListEvents();
      setEvents(json);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const date_after = dateRange[0].format("YYYY-MM-DD");
        const date_before = dateRange[1].format("YYYY-MM-DD");
        const [totalRevenue, ticketsSales, ticketsSalesByDateAndEvent, ticketSalesByDate] = await Promise.all([
          apiEventSalesByDate({ date_after, date_before }),
          apiTicketSalesByHour({ date_after, date_before }),
          apiTicketSalesByDateAndEvent({ date_after, date_before }),
          apiTicketSalesByDate({ date_after, date_before }),
        ]);

        const transformedData = ticketSalesByDate
          .map((item) => {
            const date = new Date(item.date);
            const formattedDate = `${date.getDate()}/${date.toLocaleString("default", { month: "short" })}`;
            return {
              date: formattedDate,
              tickets: item.total,
              rawDate: item.date,
            };
          })
          .sort((a, b) => new Date(a.rawDate) - new Date(b.rawDate));

        const transformedDataByDateAndEvent = ticketsSalesByDateAndEvent.reduce((acc, item) => {
          const existingEvent = acc.find((event) => event.name === item.event.name);
          if (existingEvent) {
            existingEvent.tickets += item.total;
          } else {
            acc.push({ name: item.event.name, tickets: item.total });
          }
          return acc;
        }, []);

        const peakSalesData = (() => {
          if (!ticketsSales || ticketsSales.length === 0) {
            return {
              peakHour: null,
              peakHourTotal: 0,
              totalSum: 0,
              peakPercentage: 0,
            };
          }

          const peakData = ticketsSales.reduce((prev, current) =>
            current?.total > prev?.total ? current : prev
          );

          const totalSum = ticketsSales.reduce((acc, obj) => acc + obj?.total, 0);

          return {
            peakHour: peakData?.hour,
            peakHourTotal: peakData?.total,
            totalSum: totalSum,
            peakPercentage: totalSum > 0 ? (peakData?.total / totalSum) * 100 : 0,
          };
        })();

        const totalTicketsSold = ticketsSales.reduce((sum, current) => sum + current.total, 0);

        setDataFetched({
          totalRevenue: totalRevenue.reduce((acc, obj) => acc + obj.total, 0),
          ticketsSales,
          totalTicketsSold: totalTicketsSold,
          ticketsSalesByDateAndEvent,
          peakSalesData: peakSalesData,
          ticketsSalesByDateAndEventGraphic: transformedData,
          totalRevenueGraphic: transformedDataByDateAndEvent,
        });
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange]);

  return (
    <div className='Home' style={{ backgroundColor: "#f5f5f5" }}>
      <Helmet>
        <title>Clap Tickets - Dashboard</title>
      </Helmet>
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Content
          className="content-dashboard"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="bread-top">
            <Breadcrumb
              className="breadcrumb-title"
            >
              <Breadcrumb.Item >Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            {events?.count >= 1 && <Space className="space-dates" direction="horizontal" size={12}>
              <p style={{ margin: 0 }}>Data dos eventos:</p>
              <RangePicker
                value={dateRange}
                onChange={handleChange}
                format="DD/MM/YYYY"
              />
            </Space>}
          </div>

          {events?.count === 0 && <div className="container-empty-events">
            <EmptyEvents />
            <h2>Sem dados ainda</h2>
            <p className="description-empty">
              Para visualizar seus dados, crie seu primeiro evento
            </p>
            <div className="container-empty-buttons">
              <span>Selecione o tipo de evento</span>
              <div className="empty-buttons">
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Presencial" } })}><TwoPersons /> Presencial</button>
                <button onClick={() => navigate("/eventos/criar", { state: { type: "Online" } })}><Computer /> Online</button>
              </div>
            </div>
          </div>}

          {events?.count >= 1 && <div className="container-dashboard">
            <div className="top-dashboard">
              <div className="card-top">
                <Money />
                {loading && <Skeleton paragraph={{ rows: 1 }} />}
                <div className="container-infos">
                  {!loading &&
                    <>
                      <span>Receita total</span>
                      <p className="money">{dataFetched.totalRevenue ? formatPriceInCents(dataFetched?.totalRevenue) : "R$ 0"}</p>
                    </>
                  }
                </div>
              </div>
              <div className="card-top">
                <Ticket />
                {loading && <Skeleton paragraph={{ rows: 1 }} />}
                <div className="container-infos">
                  {!loading &&
                    <>
                      <span>Tickets vendidos</span>
                      <p className="ticket">{dataFetched.totalTicketsSold ? dataFetched?.totalTicketsSold : 0}</p>
                    </>
                  }
                </div>
              </div>
              <div className="card-top clock">
                <div className="container-infos clock">
                  <Clock />
                  {loading ? <p className="clock-title"><Spin size="small" /></p> : <p className="clock-title">{dataFetched?.peakSalesData?.peakHour}H</p>}
                </div>
                {loading && <Skeleton paragraph={{ rows: 1 }} />}
                <div className="container-infos">
                  {!loading &&
                    <>
                      <span>Horário de pico de vendas</span>
                      <p className="clock-description">*{dataFetched?.peakSalesData?.peakPercentage.toFixed(0)}% dos seus ingressos foram <br /> vendidos neste horário</p>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="body-dashboard">
              <div className="card-rechart">
                <p className="title-card">Ingressos vendidos por dia</p>
                <ResponsiveContainer width="95%" height={400}>
                  <LineChart
                    data={dataFetched?.ticketsSalesByDateAndEventGraphic}
                    margin={{ top: 10, right: 30, left: -28, bottom: 10 }}
                    style={{ fontFamily: 'HankenGrotesk-Bold, sans-serif' }}
                  >
                    <XAxis dataKey="date" axisLine={false} tickLine={false} />
                    <YAxis axisLine={false} tickLine={false} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="tickets"
                      stroke="#FFA500"
                      strokeWidth={3}
                      dot={{ r: 6 }}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>

              </div>
              <div className="card-rechart">
                <p className="title-card">Tickets vendidos por evento</p>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={dataFetched?.totalRevenueGraphic}
                    margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
                    style={{ fontFamily: 'HankenGrotesk-Bold, sans-serif' }}
                  >
                    <CartesianGrid stroke="white" strokeDasharray="0" />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} padding={{ left: 30, right: 30 }} />
                    <YAxis axisLine={false} tickLine={false} />
                    <Tooltip />
                    <Bar dataKey="tickets" fill="#0047AB" barSize={Math.max(50, window.innerWidth / 50)} /> {/* Controlando a largura das barras */}
                  </BarChart>
                </ResponsiveContainer>

              </div>
            </div>
          </div>}
        </Content>
      </Layout>
    </div>
  )
}

export default Home
