import { Button, Checkbox, Divider, Form, Input, message } from "antd";
import { motion } from "motion/react";
import React, { useEffect, useState } from "react";
import { searchPostalCode } from "../../../../utils/searchPostalCode";

const PersonForm = ({ onNext, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFinish = (values) => {
    onNext({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      taxpayer_id: values.taxpayer_id,
      mother_name: values.mother_name,
      address: values.address,
    });
  };

  const validateCPF = (rule, value) => {
    const regex = /^[0-9]{11}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("CPF inválido! O CPF deve conter apenas números (sem pontos e traços).");
  };

  const validatePhone = (rule, value) => {
    const regex = /^[0-9]{11}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Telefone inválido! O telefone deve conter apenas números (sem espaços, parênteses ou traços).");
  };

  const validateEmail = (rule, value) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("E-mail inválido! Insira um e-mail no formato correto.");
  };

  const [isComplementDisabled, setIsComplementDisabled] = useState(false);
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsComplementDisabled(checked);

    if (checked) {
      form.setFieldsValue({
        address: { complement: 'Sem complemento' },
      });
    }
  };

  const handlePostalCodeChange = (value) => {
    searchPostalCode(value, (data) => {
      if (data && !data.erro) {
        form.setFieldsValue({
          address: {
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf,
            complement: '',
          },
        });
      } else {
        message.error("CEP não encontrado ou inválido.");
      }
    });
  };

  return (
    <motion.div
      className="PersonForm"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
    >
      <h2>Dados Pessoais</h2>
      <Divider />
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <div className="container-inputs-group">
          <div className="left">
            <Form.Item
              name="taxpayer_id"
              label="CPF"
              rules={[
                { required: true, message: "Insira seu CPF!" },
                { validator: validateCPF },
              ]}
            >
              <Input placeholder="CPF" />
            </Form.Item>
            <Form.Item
              name="first_name"
              label="Primeiro Nome"
              rules={[{ required: true, message: "Insira seu primeiro nome!" }]}
            >
              <Input placeholder="Primeiro Nome" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Sobrenome"
              rules={[{ required: true, message: "Insira seu sobrenome!" }]}
            >
              <Input placeholder="Sobrenome" />
            </Form.Item>
          </div>
          <div className="right">
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                { required: true, message: "Insira seu e-mail!" },
                { validator: validateEmail },
              ]}
            >
              <Input placeholder="E-mail" />
            </Form.Item>
            <Form.Item
              name="mother_name"
              label="Nome da Mãe"
              rules={[{ required: true, message: "Insira o nome da sua mãe!" }]}
            >
              <Input placeholder="Nome da Mãe" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Telefone"
              rules={[
                { required: true, message: "Insira seu telefone!" },
                { validator: validatePhone },
              ]}
            >
              <Input placeholder="Telefone" />
            </Form.Item>
          </div>
        </div>
        <div className="event-form-location">
          <div className="title-container">
            <p>Endereço?</p>
          </div>

          <div className="distribution-three">
            <Form.Item
              label="CEP"
              name={['address', 'postal_code']}
              rules={[{ required: true, message: "Informe o CEP!" }]}
            >
              <Input onBlur={(e) => handlePostalCodeChange(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Rua"
              name={['address', 'street']}
              rules={[{ required: true, message: "Informe a rua!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Número"
              name={['address', 'number']}
              rules={[{ required: true, message: "Informe o número!" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="distribution-two">
            <Form.Item
              label="Bairro"
              name={['address', 'neighborhood']}
              rules={[{ required: true, message: "Informe o bairro!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Cidade"
              name={['address', 'city']}
              rules={[{ required: true, message: "Informe a cidade!" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="distribution-finish">
            <Form.Item
              label="Estado"
              name={['address', 'state']}
              rules={[{ required: true, message: "Informe o estado!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Complemento"
              name={['address', 'complement']}
              rules={[{ required: true, message: "Informe o complemento!" }]}
            >
              <Input disabled={isComplementDisabled} />
            </Form.Item>
            <Checkbox className="edit-complement" onChange={handleCheckboxChange} >
              <p>Sem complemento</p>
            </Checkbox>
          </div>
        </div>

        <Form.Item>
          <div className="container-buttons">
            <Button type="primary" htmlType="submit" className="button-form">
              Próximo
            </Button>
          </div>
        </Form.Item>
      </Form>
    </motion.div>
  );
};

export default PersonForm;
