import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

const getDateRange = () => {
  const today = new Date();
  const dateAfter = new Date(today);
  dateAfter.setDate(today.getDate() - 30); // 30 dias atrás
  const dateBefore = new Date(today);
  dateBefore.setDate(today.getDate() + 1); // Amanhã

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return {
    date_after: formatDate(dateAfter),
    date_before: formatDate(dateBefore),
  };
};

const getUpdatedFilters = (customRange) => {
  const defaultRange = getDateRange();
  return {
    date_after: customRange?.date_after || defaultRange.date_after,
    date_before: customRange?.date_before || defaultRange.date_before,
  };
};

export const apiEventSalesByDate = async (customRange = null) => {
  try {
    const updatedFilters = getUpdatedFilters(customRange);
    const response = await authorizedFetch(
      urlFilters(
        `${baseSiteEndPoint}/event/seller/${getSellerID()}/events/metrics/eventsalesbydate`,
        updatedFilters
      ),
      {
        method: "GET",
        headers: buildHeaders(),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};


export const apiTicketSalesByHour = async (customRange = null) => {
  try {
    const updatedFilters = getUpdatedFilters(customRange);

    const response = await authorizedFetch(
      urlFilters(
        `${baseSiteEndPoint}/event/seller/${getSellerID()}/events/metrics/ticketsalesbyhour`,
        updatedFilters
      ),
      {
        method: "GET",
        headers: buildHeaders(),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiTicketSalesByDateAndEvent = async (customRange = null) => {
  try {
    const updatedFilters = getUpdatedFilters(customRange);

    const response = await authorizedFetch(
      urlFilters(
        `${baseSiteEndPoint}/event/seller/${getSellerID()}/events/metrics/ticketsalesbydateevent`,
        updatedFilters
      ),
      {
        method: "GET",
        headers: buildHeaders(),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiTicketSalesByDate = async (customRange = null) => {
  try {
    const updatedFilters = getUpdatedFilters(customRange);

    const response = await authorizedFetch(
      urlFilters(
        `${baseSiteEndPoint}/event/seller/${getSellerID()}/events/metrics/ticketsalesbydate`,
        updatedFilters
      ),
      {
        method: "GET",
        headers: buildHeaders(),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};