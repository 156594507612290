import { CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, message, Modal, Radio, Select } from 'antd';
import ptBR from "antd/lib/locale/pt_BR";
import React, { useEffect, useState } from 'react';
import { ReactComponent as Check } from '../../../assets/images/transfer/Check.svg';
import { ReactComponent as Clock } from '../../../assets/images/transfer/ClockCircle.svg';
import { ReactComponent as Ellipsis } from '../../../assets/images/transfer/Ellipsis.svg';
import { ReactComponent as Success } from '../../../assets/images/transfer/Success.svg';
import { formatPrice } from '../../../utils/format';
import './TransferModal.scss';

const { Option } = Select;

const TransferModal = ({ visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [priceTransfer, setPriceTransfer] = useState(0);
  const [errors, setErrors] = useState({});
  const [internalCurrentStep, setInternalCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCurrentStep(0);
      setInternalCurrentStep(0);
      setPriceTransfer(0);
    }
  }, [visible]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setPriceTransfer(parseInt(numericValue, 10) || 0);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep < 2) {
          setCurrentStep(currentStep + 1);
        } else if (currentStep === 2 && internalCurrentStep === 0) {
          setInternalCurrentStep(1);
        } else if (currentStep === 2 && internalCurrentStep === 1) {
          // message.success("Transferência bem sucedida!");
          setSuccessModal(true);
        }
      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  };

  const handleConfirmOK = () => {
    message.info("Transferência cancelada com sucesso!");
    setShowModal(false);
    onClose();
  };

  const successModalOK = () => {
    setSuccessModal(false);
    onClose();
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const stepTitles = ['Destinatário', 'Valor', 'Finalizar saque'];

  return (
    <>
      <Modal
        className="transfer-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <h2 className="ant-modal-title">Transferir Saldo</h2>
        <div className="custom-steps">
          {stepTitles.map((title, index) => (
            <>
              <div className="step" key={index}>
                <div className={`step-icon ${currentStep > index ? 'step-finish' : currentStep === index ? 'step-active' : ''}`}>
                  {currentStep < index && <Ellipsis />}
                  {index === currentStep && <Clock />}
                  {currentStep > index && <Check />}
                </div>
                <span className={`step-title ${currentStep >= index ? 'step-active-title' : ''} ${currentStep > index ? 'step-finish' : ''}`}>
                  {title}
                </span>
              </div>
              {index < stepTitles.length - 1 && <div className="line" />}
            </>
          ))}
        </div>
        <div className="body-transfer">
          <Form form={form} layout="vertical" style={{ marginTop: 20 }}>
            {currentStep === 0 && (
              <>
                <div className="subtitle-body">
                  <h3>Para quem deseja transferir?</h3>
                </div>
                <Form.Item
                  name="name"
                  label="Nome completo"
                  rules={[{ required: true, message: 'Por favor, insira o nome completo!' }]}
                >
                  <Input />
                </Form.Item>
                <div className="two-body-inputs">
                  <Form.Item
                    name="cpf"
                    label="CPF"
                    rules={[{ required: true, message: 'Por favor, insira o CPF!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="personType"
                    className="type-hidden"
                    label="Tipo de pessoa"
                    rules={[{ required: true, message: 'Por favor, selecione o tipo de pessoa!' }]}
                  >
                    <Radio.Group>
                      <Radio value="fisica">Pessoa Física</Radio>
                      <Radio value="juridica">Pessoa Jurídica</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>

                <div className="three-body-inputs">
                  <Form.Item
                    style={{ width: '100%' }}
                    name="bank"
                    label="Instituição"
                    rules={[{ required: true, message: 'Por favor, selecione uma instituição!' }]}
                  >
                    <Select placeholder="Selecione o banco">
                      <Option value="banco1">Banco 1</Option>
                      <Option value="banco2">Banco 2</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="agency"
                    label="Agência"
                    rules={[{ required: true, message: 'Por favor, insira a agência!' }]}
                  >
                    <Input placeholder="000" />
                  </Form.Item>
                  <Form.Item
                    name="account"
                    label="Conta"
                    rules={[{ required: true, message: 'Por favor, insira o número da conta!' }]}
                  >
                    <Input placeholder="Com dígito" />
                  </Form.Item>
                </div>
                <Form.Item
                  name="accountType"
                  label="Tipo de conta"
                  rules={[{ required: true, message: 'Por favor, selecione o tipo de conta!' }]}
                >
                  <Radio.Group>
                    <Radio value="poupanca">Conta Poupança</Radio>
                    <Radio value="corrente">Conta Corrente</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {currentStep === 1 && (
              <>
                <div className="subtitle-body">
                  <h3>Qual o valor da transferência/saque?</h3>
                  <span>Saldo disponível de R$ 2.773,85</span>
                </div>
                <Form.Item
                  validateStatus={errors.amount ? 'error' : ''}
                  help={errors.amount}
                  label="Digite o valor desejado"
                  style={{ width: '100%', maxWidth: 300 }}
                  rules={[{ required: true, message: 'Por favor, insira o valor!' }]}
                >
                  <Input
                    value={formatPrice(priceTransfer / 100)}
                    onChange={handleAmountChange}
                  />
                </Form.Item>
              </>
            )}
            {currentStep === 2 && internalCurrentStep === 0 && (
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Para quando deseja realizar essa transferência?"
                  name="transferOption"
                  rules={[{ required: true, message: "Por favor, selecione uma opção!" }]}
                >
                  <Radio.Group className="transfer-option">
                    <Radio value="agora">
                      Agora - Receber em até 1 dia útil
                    </Radio>
                    <Radio value="agendar">Agendar</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="dateTransfer"
                  label="Data de agendamento"
                  dependencies={['transferOption']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('transferOption') === 'agendar',
                      message: 'Por favor, selecione uma data!',
                    }),
                  ]}
                >
                  <div className="date-picker-container">
                    <CalendarOutlined style={{ color: "#1890ff", fontSize: "20px" }} />
                    <DatePicker
                      format="MMM DD, YYYY"
                      placeholder="Jun, 10 2024"
                      locale={ptBR}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      suffixIcon={null}
                      allowClear={false}
                      onChange={(date) => form.setFieldsValue({ dateTransfer: date })}
                    />
                  </div>
                </Form.Item>

                <Alert
                  message="Atenção: as transferências são feitas apenas em dias úteis, durante o horário comercial."
                  type="warning"
                  showIcon
                  style={{ marginTop: "10px" }}
                />
              </Form>
            )}




            {currentStep === 2 && internalCurrentStep === 1 && (
              <div className="resume">
                <h3>Confira as informações antes de finalizar</h3>
                <p className="subtitle">Realizando transferência para:</p>

                <div className="confirmation-card">

                  <div className="info-card">
                    <div className="info-row">
                      <span className="label">Valor</span>
                      <span className="value">{formatPrice(priceTransfer / 100)}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Nome</span>
                      <span className="value">{form.getFieldValue('name')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">CPF</span>
                      <span className="value">{form.getFieldValue('cpf')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Instituição</span>
                      <span className="value">{form.getFieldValue('bank')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Agência</span>
                      <span className="value">{form.getFieldValue('agency')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Conta</span>
                      <span className="value">{form.getFieldValue('account')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Tipo de conta</span>
                      <span className="value">{form.getFieldValue('accountType') === 'poupanca' ? 'Conta Poupança' : 'Conta Corrente'}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Data de transferência</span>
                      <span className="value">
                        {form.getFieldValue('transferOption') === 'agora'
                          ? 'Agora - Receber em até 1 dia útil'
                          : form.getFieldValue('dateTransfer')?.format('MMM DD, YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            )}
            <div className='container-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
              <Button style={{ marginRight: 8 }} onClick={handleCancel} danger>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleNext}>
                {currentStep < 3 && internalCurrentStep === 0 ? 'Próximo' : 'Finalizar'}
              </Button>
            </div>
          </Form>
        </div>

      </Modal>
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: "orange", marginRight: "8px" }} />
            Atenção
          </span>
        }
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleConfirmOK}
          >
            Sim
          </Button>,
        ]}
        open={showModal}
        onCancel={() => setSuccessModal(false)}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <p>Tem certeza que deseja cancelar a solicitação de transferência?</p>
      </Modal>

      <Modal
        className='success-modal'
        width={600}
        title={
          <span className='title-modal'>
            Tudo certo! Saque solicitado
          </span>
        }
        footer={[
          <Button
            className='button-stop'
            key="submit"
            type="primary"
            onClick={successModalOK}
          >
            Fechar
          </Button>,
        ]}
        open={successModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <div className='container-success'>
          <Success />
          <p>Pronto! Sua transferência foi solicitada com sucesso. </p>
          <p>O valor estará disponível em breve!</p>
        </div>
      </Modal>
    </>
  );
};

export default TransferModal;
