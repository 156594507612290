import { CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, message, Modal, Radio } from 'antd';
import ptBR from "antd/lib/locale/pt_BR";
import React, { useEffect, useState } from 'react';
import { ReactComponent as Check } from '../../../assets/images/transfer/Check.svg';
import { ReactComponent as Clock } from '../../../assets/images/transfer/ClockCircle.svg';
import { ReactComponent as Ellipsis } from '../../../assets/images/transfer/Ellipsis.svg';
import { ReactComponent as Success } from '../../../assets/images/transfer/Success.svg';
import './TransferModalBoleto.scss';

const TransferModalBoleto = ({ visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCurrentStep(0);
    }
  }, [visible]);

  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentStep < 2) {
          setCurrentStep(currentStep + 1);
        } else {
          setSuccessModal(true);
        }
      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  };

  const handleConfirmOK = () => {
    message.info("Transferência cancelada com sucesso!");
    setShowModal(false);
    onClose();
  };

  const successModalOK = () => {
    setSuccessModal(false);
    onClose();
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const stepTitles = ['Colar boleto', 'Agendamento', 'Conferência'];
  console.log(currentStep)
  return (
    <>
      <Modal
        className="transfer-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <h2 className="ant-modal-title">Pagar Boleto</h2>
        <div className="custom-steps">
          {stepTitles.map((title, index) => (
            <>
              <div className="step" key={index}>
                <div className={`step-icon ${currentStep > index ? 'step-finish' : currentStep === index ? 'step-active' : ''}`}>
                  {currentStep < index && <Ellipsis />}
                  {index === currentStep && <Clock />}
                  {currentStep > index && <Check />}
                </div>
                <span className={`step-title ${currentStep >= index ? 'step-active-title' : ''} ${currentStep > index ? 'step-finish' : ''}`}>
                  {title}
                </span>
              </div>
              {index < stepTitles.length - 1 && <div className="line" />}
            </>
          ))}
        </div>
        <div className="body-transfer">
          <Form form={form} layout="vertical" style={{ marginTop: 20 }}>
            {currentStep === 0 && (
              <>
                <div className="subtitle-body">
                  <h3>Cole o código do boleto</h3>
                </div>
                <Form.Item
                  name="name"
                  label="Código do boleto"
                  rules={[{ required: true, message: 'Por favor, insira o código!' }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            {currentStep === 1 && (
              <Form layout="vertical" form={form}>
                <Form.Item
                  label="Para quando deseja realizar essa transferência?"
                  name="transferOption"
                  rules={[{ required: true, message: "Por favor, selecione uma opção!" }]}
                >
                  <Radio.Group className="transfer-option">
                    <Radio value="agora">
                      Receber em até 3 dias úteis
                    </Radio>
                    <Radio value="agendar">Agendar</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="dateTransfer"
                  label="Data de agendamento"
                  dependencies={['transferOption']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('transferOption') === 'agendar',
                      message: 'Por favor, selecione uma data!',
                    }),
                  ]}
                >
                  <div className="date-picker-container">
                    <CalendarOutlined style={{ color: "#1890ff", fontSize: "20px" }} />
                    <DatePicker
                      format="MMM DD, YYYY"
                      placeholder="Jun, 10 2024"
                      locale={ptBR}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      suffixIcon={null}
                      allowClear={false}
                      onChange={(date) => form.setFieldsValue({ dateTransfer: date })}
                    />
                  </div>
                </Form.Item>

                <Alert
                  message="Atenção: Os pagamentos são feitos apenas em dias úteis, durante o horário comercial."
                  type="warning"
                  showIcon
                  style={{ marginTop: "10px" }}
                />
              </Form>
            )}


            {currentStep === 2 && (
              <div className="resume">
                <h3>Confira as informações antes de finalizar</h3>
                <p className="subtitle">Realizando transferência para:</p>

                <div className="confirmation-card">

                  <div className="info-card">
                    <div className="info-row">
                      <span className="label">Código do boleto</span>
                      <span className="value">{form.getFieldValue('name')}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">Data de transferência</span>
                      <span className="value">
                        {form.getFieldValue('transferOption') === 'agora'
                          ? 'Receber em até 3 dias úteis'
                          : form.getFieldValue('dateTransfer')?.format('MMM DD, YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            )}
            <div className='container-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 40 }}>
              <Button style={{ marginRight: 8 }} onClick={handleCancel} danger>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleNext}>
                {currentStep === 2 ? 'Finalizar' : 'Próximo'}
              </Button>
            </div>
          </Form>
        </div>

      </Modal>
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: "orange", marginRight: "8px" }} />
            Atenção
          </span>
        }
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleConfirmOK}
          >
            Sim
          </Button>,
        ]}
        open={showModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <p>Tem certeza que deseja cancelar a solicitação de transferência?</p>
      </Modal>

      <Modal
        className='success-modal'
        width={600}
        title={
          <span className='title-modal'>
            Tudo certo! Pagamento solicitado
          </span>
        }
        footer={[
          <Button
            className='button-stop'
            key="submit"
            type="primary"
            onClick={successModalOK}
          >
            Fechar
          </Button>,
        ]}
        open={successModal}
        onCancel={handleCancelModal}
        okText="Sim"
        cancelText="Não"
        destroyOnClose={true}
      >
        <div className='container-success'>
          <Success />
          <p>Pronto! Seu pagamento foi solicitada com sucesso. </p>
          <p>O valor estará disponível em breve!</p>
        </div>
      </Modal>
    </>
  );
};

export default TransferModalBoleto;
