import { Button, message } from "antd";
import { motion } from "motion/react";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { register } from "../../../../api/register";
import './Summary.scss';

const Summary = ({ formData, accessToken }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      console.log(formData)
      await register(formData, accessToken);
      message.success("Conta criada com sucesso!");
      setLoading(false);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      message.error("Houve um erro ao tentar criar sua conta, tente novamente.");
      navigate('/login');
    }
  };


  return (
    <motion.div
      className="Summary"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
    >
      <h2>Resumo dos Dados</h2>
      {/* Dados Pessoais */}
      <div className="section one">
        <div className="section-header">Dados Pessoais</div>
        <div className="section-content">
          <div className="field">
            <div className="label">Nome:</div>
            <div className="value">{formData?.person?.first_name} {formData?.person?.last_name}</div>
          </div>
          <div className="field">
            <div className="label">CPF:</div>
            <div className="value">{formData?.person?.taxpayer_id}</div>
          </div>
          <div className="field">
            <div className="label">Email:</div>
            <div className="value">{formData?.person?.email}</div>
          </div>
          <div className="field">
            <div className="label">Telefone:</div>
            <div className="value">{formData?.person?.phone}</div>
          </div>
          <div className="field">
            <div className="label">Nome da Mãe:</div>
            <div className="value">{formData?.person?.mother_name}</div>
          </div>
        </div>
      </div>

      {/* Endereço Pessoal */}
      <div className="section">
        <div className="section-header">Endereço Pessoal</div>
        <div className="section-content">
          <div className="field">
            <div className="label">Rua:</div>
            <div className="value">{formData?.person?.address?.street}</div>
          </div>
          <div className="field">
            <div className="label">Número:</div>
            <div className="value">{formData?.person?.address?.number}</div>
          </div>
          <div className="field">
            <div className="label">Bairro:</div>
            <div className="value">{formData?.person?.address?.neighborhood}</div>
          </div>
          <div className="field">
            <div className="label">Cidade:</div>
            <div className="value">{formData?.person?.address?.city}</div>
          </div>
          <div className="field">
            <div className="label">Estado:</div>
            <div className="value">{formData?.person?.address?.state}</div>
          </div>
          <div className="field">
            <div className="label">Complemento:</div>
            <div className="value">{formData?.person?.address?.complement || 'Sem complemento'}</div>
          </div>
        </div>
      </div>

      {/* Dados da Empresa */}
      {formData?.company && Object.keys(formData?.company).length >= 1 && (
        <div className="section">
          <div className="section-header">Dados da Empresa</div>
          <div className="section-content">
            <div className="field">
              <div className="label">Nome da Empresa:</div>
              <div className="value">{formData?.company?.name}</div>
            </div>
            <div className="field">
              <div className="label">CNPJ:</div>
              <div className="value">{formData?.company?.tax_id}</div>
            </div>
            <div className="field">
              <div className="label">Email da Empresa:</div>
              <div className="value">{formData?.company?.email}</div>
            </div>
            <div className="field">
              <div className="label">Telefone da Empresa:</div>
              <div className="value">{formData?.company?.phone}</div>
            </div>
          </div>
        </div>
      )}

      {/* Endereço da Empresa */}
      {formData?.company?.address && (
        <div className="section">
          <div className="section-header">Endereço da Empresa</div>
          <div className="section-content">
            <div className="field">
              <div className="label">Rua:</div>
              <div className="value">{formData?.company?.address?.street}</div>
            </div>
            <div className="field">
              <div className="label">Número:</div>
              <div className="value">{formData?.company?.address?.number}</div>
            </div>
            <div className="field">
              <div className="label">Bairro:</div>
              <div className="value">{formData?.company?.address?.neighborhood}</div>
            </div>
            <div className="field">
              <div className="label">Cidade:</div>
              <div className="value">{formData?.company?.address?.city}</div>
            </div>
            <div className="field">
              <div className="label">Estado:</div>
              <div className="value">{formData?.company?.address?.state}</div>
            </div>
            <div className="field">
              <div className="label">Complemento:</div>
              <div className="value">{formData?.company?.address?.complement || 'Sem complemento'}</div>
            </div>
          </div>
        </div>
      )}
      <div className="container-buttons">
        <Button loading={loading} type="primary" onClick={() => handleSubmit()} className="button-form">
          CRIAR CONTA
        </Button>
      </div>
    </motion.div>
  );
};

export default Summary;
