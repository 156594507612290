import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { registerLogin } from "../../../../api/register";
import logo from "../../../../assets/images/logo.png";
import CompanyForm from "../CompanyForm/CompanyForm";
import PersonForm from "../PersonForm/PersonForm";
import RegisterPerson from "../RegisterPerson/RegisterPerson";
import Summary from "../Summary/Summary";
import TypeSelection from "../TypeSelection/TypeSelection";
import "./StepsRegister.scss";

const StepsRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    type: "",
    person: {},
    company: {},
    email: '',
  });
  const [usePersonAddress, setUsePersonAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (state) {
      setCurrentStep(2);
      setFormData((prev) => ({ ...prev, email: state?.user?.email }));
      setAccessToken(state.access);
    }
  }, [state]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = await registerLogin(values.email, values.password);
      setLoading(false);
      setFormData((prev) => ({ ...prev, email: values.email }));
      setAccessToken(data.access);
      setCurrentStep(2);
    } catch (error) {
      setLoading(false);
      if (error?.message?.email[0] === 'Esse campo deve ser  único.') {
        message.error("Este e-mail já está cadastrado.");
        navigate('/login');
      }
    }
  };

  const handleTypeSelection = (type) => {
    setFormData((prev) => {
      if (type === "SUB_SELLER_PF") {
        const { company, ...rest } = prev;
        return { ...rest, type };
      }
      return { ...prev, type };
    });

    setCurrentStep(3);
  };

  const steps = [
    { number: 1, label: "Criação de Login" },
    { number: 2, label: "Tipo de Conta" },
    ...(formData.type === "SUB_SELLER_PJ" ? [
      { number: 3, label: "Dados Pessoais" },
      { number: 4, label: "Dados da Empresa" }
    ] : [{ number: 3, label: "Dados Pessoais" }]),
    { number: formData.type === "SUB_SELLER_PJ" ? 5 : 4, label: "Resumo" },
  ];

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <RegisterPerson onSubmitRegister={handleSubmit} loading={loading} />;
      case 2:
        return <TypeSelection onNext={handleTypeSelection} />;
      case 3:
        return (
          <PersonForm
            onNext={(data) => {
              setFormData((prev) => ({ ...prev, person: data }));
              setCurrentStep(4);

            }}
            initialValues={formData.person}
          />
        );
      case 4:
        return formData.type === "SUB_SELLER_PJ" ? (
          <CompanyForm
            personAddress={usePersonAddress ? formData.person?.address : null}
            onNext={(data) => {
              setFormData((prev) => ({ ...prev, company: data }));
              setCurrentStep(5);
            }}
            usePersonAddress={usePersonAddress}
            setUsePersonAddress={setUsePersonAddress}
            initialValues={formData.company}
          />
        ) : (
          <Summary formData={formData} accessToken={accessToken} />
        );
      case 5:
        return <Summary formData={formData} accessToken={accessToken} />;
      default:
        return <div>Select a step</div>;
    }
  };
  return (
    <>
      <div style={{ width: '100%', height: '66px', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ height: '100%' }} />
      </div>
      <div className="StepsRegister">
        <div className="container-steps">
          {steps.map((step) => (
            <div
              key={step.number}
              className={`step ${currentStep === step.number ? "active" : ""} 
                ${currentStep > step.number ? "disabled" : ""}`}
              onClick={() => {
                if (step.number < currentStep && step.number !== 1) {
                  setCurrentStep(step.number);
                }
              }}
            >
              <div className="circle">{step.number}</div>
              <div className="text">
                <span className="step-label">ETAPA {step.number}</span>
                <span className="step-name">{step.label}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="container-registers">{renderStepContent()}</div>
      </div>
    </>
  );
};

export default StepsRegister;
