import {
  CalendarOutlined,
  MenuOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import {
  getItemFromStorage
} from "../../../helpers/storage";
import { useIsMobileHook } from "../../../utils/WindowHooks";
import "./PanelLayout.scss";

const { Header, Content, Sider } = Layout;
const user = getItemFromStorage("userInfo");

const navItems = [
  {
    key: "0",
    icon: React.createElement(PieChartOutlined),
    path: "/",
    label: "Dashboard",
  },
  {
    key: "1",
    icon: React.createElement(CalendarOutlined),
    path: "/eventos",
    label: "Meu eventos",
  },
  {
    key: "2",
    icon: React.createElement(UserOutlined),
    path: "/conta",
    label: "Minha conta",
  },
];

const PanelLayout = ({ children }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobileHook(1020);
  const location = useLocation();
  const [openUserMenu, setOpenUserMenu] = useState(false);

  useEffect(() => {
    if (!getItemFromStorage("menu")) {
      localStorage.setItem("menu", isMobile);
    }
  }, []);

  const onClick = (e) => {
    const { key } = e;
    const item = findMenuItemByKey(navItems, key);
    if (item) {
      if (item.onClick) {
        item.onClick();
      } else if (item.path) {
        navigate(item.path);
      }
    }
  };

  const findMenuItemByKey = (items, key) => {
    for (let item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findMenuItemByKey(item.children, key);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const [collapsed, setCollapsed] = useState(() => {
    return isMobile;
  });

  const toggleMenu = () => {
    let menuState = localStorage.getItem("menu");
    menuState = menuState === "true";
    menuState = !menuState;
    localStorage.setItem("menu", menuState);
    setCollapsed(menuState);
  };
  const toggleCollapsed = () => {
    toggleMenu();
  };

  const [collapsedDesktop, setCollapsedDesktop] = useState(true);
  const toggleCollapsedDesktop = () => {
    setCollapsedDesktop(!collapsedDesktop);
  };

  const getSelectedKeys = (path) => {
    const keys = [];
    navItems.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.path === path) {
            keys.push(child.key);
          }
        });
      } else if (item.path === path) {
        keys.push(item.key);
      }
    });
    return keys;
  };

  const user = getItemFromStorage("userInfo");
  const filteredNavItems = navItems.filter((item) => {
    // if (item.label === "Operação" || item.label === "Sistema" || item.label === "Lojistas / Distribuidores") {
    //   return JSON.parse(user)?.is_superuser;
    // }
    return true;
  });

  const selectedKeys = getSelectedKeys(location.pathname);
  const openKeys = selectedKeys.map((key) => key.split("-")[0]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    if (openUserMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openUserMenu]);

  const handleCloseModal = () => {
    setOpenUserMenu(false);
  };

  const handleOpenModal = () => {
    setOpenUserMenu(true);
  };

  return (
    <Layout className={"PanelLayout"}>
      <Header
        className={"header"}
        style={{
          background: colorBgContainer,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          zIndex: 1000,
          width: "100%",
        }}
      >
        <img src={logo} alt="Logo" onClick={() => navigate("/")} style={{ cursor: "pointer" }} />
        <div className="right-buttons">
          {isMobile && <Button type="text" onClick={toggleCollapsed}>
            <MenuOutlined />
          </Button>}
          <Button
            type="text"
            ref={modalRef}
            onClick={openUserMenu ? handleCloseModal : handleOpenModal}
          >
            <UserOutlined />
            {openUserMenu && (
              <div className="container-user">
                <div className="header-user">
                  <span> {JSON.parse(user)?.username}</span>
                  <p>
                    {JSON.parse(user)?.is_superuser
                      ? "Administrador"
                      : "Vendedor"}
                  </p>
                </div>
                <div className="container-buttons-user">
                  <Button
                    onClick={() => {
                      localStorage.clear();
                      sessionStorage.clear();
                      window.location.href = "/login";
                    }}
                  >
                    <PoweroffOutlined /> Sair
                  </Button>
                </div>
              </div>
            )}
          </Button>
        </div>
      </Header>
      <Layout style={{ marginTop: 64 }}>
        <Sider
          // collapsible
          collapsed={isMobile ? collapsed : collapsedDesktop}
          width={isMobile ? "100%" : collapsedDesktop ? 0 : 250}
          className="teste"
          style={{
            backgroundColor: "white",
            color: "white",
            height: "100%",
            position: isMobile ? "absolute" : "relative",
            zIndex: 1,
            top: isMobile ? 64 : 0,
            left: 0,
            display: collapsed ? "none" : "block",
          }}
        >
          {!isMobile && <div className={`menu-click ${collapsedDesktop ? "" : "active"}`} onClick={toggleCollapsedDesktop}>
            <MenuOutlined />
          </div>}
          <Menu
            // theme="#F5F6FA"
            mode="inline"
            onClick={onClick}
            // inlineCollapsed={collapsedDesktop}
            inlineCollapsed={isMobile ? collapsed : collapsedDesktop}
            selectedKeys={selectedKeys}
            defaultOpenKeys={openKeys}
            style={{
              borderRight: 0,
              backgroundColor: "white",
              paddingBottom: "250px",
            }}
          >
            {filteredNavItems.map((item) =>
              item.children ? (
                <SubMenu key={item.key} title={item.label} icon={item.icon}>
                  {item.children.map((child) => (
                    <Menu.Item key={child.key} disabled={!child.path && true}>
                      {child.path ? (
                        <Link
                          onClick={() => isMobile && toggleCollapsed()}
                          to={child.path}
                        >
                          {child.label}
                        </Link>
                      ) : (
                        <span>{child.label}</span>
                      )}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  disabled={item.disabled}
                >
                  {item.path ? (
                    <Link
                      onClick={() => isMobile && toggleCollapsed()}
                      to={item.path}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <span>{item.label}</span>
                  )}
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout
          style={{ overflowX: "hidden" }}
        >
          <Layout style={{ overflowY: "scroll" }}>{children}</Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default PanelLayout;
