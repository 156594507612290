import { baseSiteEndPoint, buildHeaders } from "./common";

export async function registerLogin(email, password) {
  const response = await fetch(`${baseSiteEndPoint}/user/register/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const error = {
      status: response.status,
      message: errorResponse || response.statusText || response.message,
    };
    throw error;
  }

  const data = await response.json();
  return data;
}

export async function register(payload, accessToken) {
  const response = await fetch(`${baseSiteEndPoint}/unified/accounts/sellers/1c7ff1f9-99b9-4229-ad1f-6e2648911367/sub/`, {
    method: "POST",
    headers: buildHeaders(accessToken),
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    const error = {
      status: response.status,
      message: errorResponse || response.statusText || response.message,
    };
    throw error;
  }

  const data = await response.json();
  return data;
}